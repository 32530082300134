import { FC } from "react";
import { useIsAuthenticated } from "../hooks/authentication-hooks/authenticationHooks";
import { useKeycloakUserToRedux } from "../hooks/user-hooks/userHooks";
import SignalRToReduxProvider from "../provider/SignalRToReduxProvider";

export const PrivateRoute: FC = ({ children }) => {
  const { authDone, isAuthenticated } = useIsAuthenticated();
  useKeycloakUserToRedux()
  return (
    <>
      {isAuthenticated && authDone && (
        <SignalRToReduxProvider>
          <>{children}</>
        </SignalRToReduxProvider>
      )}
    </>
  );
};


