import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../provider/AuthContextProvider";
import { setProfile, setUserInfo } from "../../store/actions/userActions";

export const useKeycloakUserToRedux = () => {
  const keycloak = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (keycloak.profile) dispatch(setProfile(keycloak.profile));
  }, [keycloak.profile, dispatch]);
  useEffect(() => {
    if (keycloak.userInfo) dispatch(setUserInfo(keycloak.userInfo));
  }, [keycloak.userInfo, dispatch]);
};
